import { useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useScale } from '../../hooks/useScale';
import { useColors } from '../../hooks/useColors';
import theme from '../../common/theme';

const MainScreenStyles = () => {
  const { scale } = useScale();
  const { colors } = useColors();
  const insets = useSafeAreaInsets();

  return useMemo(
    () =>
      StyleSheet.create({
        loader: {
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        },
        screen: {
          flex: 1,
        },
        header: {
          padding: scale(16),
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: colors.teritiary2,
          paddingTop: scale(16 + insets.top),
        },
        buttons: {
          flexDirection: 'row',
          flex: 1,
        },
        content: {
          flex: 1,
          flexDirection: 'row',
          backgroundColor: colors.elevation0,
        },
        sidebarMargin: {
          marginLeft: scale(300),
        },
        layout: {
          flex: 1,
          maxHeight: theme.window.vh - 78,
        },
        grid: {
          flex: 1,
          padding: scale(10),
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          alignContent: 'flex-start',
        },
        item: {
          marginRight: scale(10),
          paddingHorizontal: scale(4),
          backgroundColor: colors.elevation1,
        },
        emptyView: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        },
        emptyText: {
          fontSize: scale(16),
          color: colors.textLight,
          fontFamily: theme.fonts.medium,
        },
        btnSort: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: 10,
        },
        buttonGap: {
          marginRight: 10,
        },
      }),
    [colors, scale, insets],
  );
};

export default MainScreenStyles;
