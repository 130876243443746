import React, { useCallback, useEffect, useRef } from 'react';
import {
  CommonActions,
  NavigationContainer,
  NavigationContainerRef,
  useNavigation,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from '../screens/Login/LoginScreen';
import MainScreen from '../screens/Main/MainScreen';
import { useSession } from '../hooks/useSession';
import NetworkStatus from '../common/blocks/NetworkStatus';
import DocketAudioNotification from '../common/blocks/DocketAudioNotification';
import { DocketBumperProvider } from '../screens/Main/Dockets/DocketBumper';
import { Tracer } from '@oolio-group/tracer-client';
import LogRocket from '@logrocket/react-native';
import { identifyLogRocketUser } from '../utils/logRocketHelper';
import {
  LOG_ROCKET_ID,
  LOG_ROCKET_TRACKING_BUZA_MC_ORG_ID,
} from '../common/constants';
import SettingsScreen from '../screens/Settings/Settings';
import DeviceSelection from '../screens/DeviceSelection/DeviceSelection';
import { useInitialScreen } from '../hooks/useInitialScreen';
import { View } from 'react-native';
import { useReactiveVar } from '@apollo/client/react/hooks';
import { tokenState } from '../state/apolloVars';

const Stack = createStackNavigator();
const AppStack = () => {
  const { session } = useSession();
  const { screen: initialScreen } = useInitialScreen();
  const navigation = useNavigation();

  useEffect(() => {
    if (
      session.kitchenDisplay &&
      (session.currentOrganization?.id?.toLowerCase() || '').includes(
        LOG_ROCKET_TRACKING_BUZA_MC_ORG_ID,
      )
    ) {
      LogRocket.init(LOG_ROCKET_ID);
      identifyLogRocketUser(session);
    }
  }, [session]);

  const tokenStateValue = useReactiveVar(tokenState);
  useEffect(() => {
    if (!tokenStateValue?.token) {
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{ name: 'LoginScreen' }],
        }),
      );
    }
  }, [navigation, tokenStateValue]);

  return initialScreen !== 'Idle' ? (
    <Stack.Navigator
      initialRouteName={initialScreen}
      screenOptions={{ headerShown: false }}
    >
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{ title: 'Login | Oolio KDS' }}
      />
      <Stack.Screen
        name="AssignKds"
        component={DeviceSelection}
        options={{ title: 'Assign KDS | Oolio KDS', gestureEnabled: false }}
      />
      <Stack.Screen
        name="MainScreen"
        component={MainScreen}
        options={{ title: 'Home | Oolio KDS', gestureEnabled: false }}
      />
      <Stack.Screen
        name="Settings"
        component={SettingsScreen}
        options={{ title: 'Settings | Oolio KDS', gestureEnabled: false }}
      />
    </Stack.Navigator>
  ) : (
    <View />
  );
};

const AppNavigator = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigationRef = useRef<NavigationContainerRef<any>>(null);
  const onNavigationStateChange = useCallback(() => {
    const route = navigationRef.current?.getCurrentRoute();
    Tracer.getInstance().onNavigationStateChange({
      name: route?.name,
      params: route?.params,
    });
  }, []);

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={onNavigationStateChange}
    >
      <DocketBumperProvider>
        <AppStack />
      </DocketBumperProvider>
      <NetworkStatus />
      <DocketAudioNotification />
    </NavigationContainer>
  );
};

export default AppNavigator;
