import { Docket, DocketStatus } from '@oolio-group/domain';
import React, { useCallback, useMemo } from 'react';
import useSWR from 'swr';
import { DocketSource } from '../../../common/types';
import config from '../../../config';
import { useOrgTimings } from '../../../hooks/useOrgTimings';
import { useSession } from '../../../hooks/useSession';
import { useOrderNotification } from '../../../hooks/useOrderNotification';
import {
  openDocketslastUpdatedAt,
  tokenState,
} from '../../../state/apolloVars';
import { saveDocketsOverwrite } from '../../../storage/docket';
import { clearSession } from '../../../utils/authFlowLink';
import { headers } from '../../../utils/fetchHeaders';
import { refetchTokenSerially } from '../../../utils/refetchTokenSerially';
import { getStartTimeandEndTime } from '../../../utils/timeHelper';
import * as settings from '../../../state/preferences';
import {
  POSTHOG_EVENT_ACTIONS,
  POSTHOG_EVENT_DATA,
  analyticsService,
} from '../../../analytics/AnalyticsService';

export interface DocketHermesProps {
  docketStatus: DocketStatus;
  headers: headers;
}

export interface DocketResponse {
  dockets: Docket[];
  lastUpdatedAt: number;
}
/*
The Purpose of this block is to do pooling of dockets at a fixed interval
*/

const DocketHermes: React.FC<DocketHermesProps> = ({
  docketStatus,
  headers,
}) => {
  let isRefreshing = false;
  const { session } = useSession();
  const { dateTime } = useOrgTimings();
  const timings = useMemo(
    () => getStartTimeandEndTime(dateTime?.startTime || ''),
    [dateTime?.startTime],
  );
  const { sendReceiveSmsNotification } = useOrderNotification();
  const { notifyOnReceive } = session.kitchenDisplay?.notificationConfig || {};

  const printerProfiles = (session?.kitchenDisplay?.printerProfiles || [])?.map(
    x => x && x.id,
  );

  const storeId = session?.currentStore?.id;

  const fetcher = useCallback(
    async (url: string) => {
      return fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          ids: printerProfiles,
          status: docketStatus,
          ...(docketStatus == DocketStatus.COMPLETED && {
            startTime: timings.startTime,
            endTime: timings.endTime,
          }),
          ...(storeId && {
            storeId,
          }),
        }),
        headers: headers,
      }).then(async r => {
        if (r.ok) return r.json();
        throw new Error(await r.text());
      });
    },
    [
      docketStatus,
      headers,
      printerProfiles,
      storeId,
      timings.endTime,
      timings.startTime,
    ],
  );
  useSWR(config.KDS_SERVICE_URI + '/dockets', fetcher, {
    refreshInterval: 10 * 1000, // 10 seconds polling
    dedupingInterval: 10 * 1000, // 10 seconds polling
    onSuccess(docketsData) {
      const docketResponseData = docketsData as DocketResponse;
      if (!isNaN(docketResponseData?.lastUpdatedAt)) {
        openDocketslastUpdatedAt(docketResponseData?.lastUpdatedAt);
      }
      const dockets = docketResponseData.dockets;
      if (
        docketStatus === DocketStatus.CREATED &&
        notifyOnReceive &&
        dockets?.length
      ) {
        const unNotifiedDockets = dockets.filter(
          d => !d?.notificationStatus?.smsOnReceive,
        );
        if (unNotifiedDockets?.length) {
          sendReceiveSmsNotification(unNotifiedDockets.map(item => item.id));
        }
      }
      if (docketStatus) {
        saveDocketsOverwrite(dockets || [], docketStatus, DocketSource.POOLING);
      }
    },
    onError() {
      if ((tokenState().expiredDate as number) < Date.now() && !isRefreshing) {
        isRefreshing = true;
        refetchTokenSerially()
          .catch(error => {
            // logout user
            console.error('FAIL TO REFRESH USER TOKEN', error);
            clearSession();
            tokenState({});
          })
          .finally(async () => {
            isRefreshing = false;
            const session = await settings.getSession();
            analyticsService.capture(
              POSTHOG_EVENT_ACTIONS.TOKEN_REFRESH_OR_EXPIRED,
              {
                orgId: session?.currentOrganization?.id,
                orgName: session?.currentOrganization?.name,
              } as POSTHOG_EVENT_DATA,
            );
          });
      }
    },
  });

  return <></>;
};

export default DocketHermes;
