import { useCallback, useContext } from 'react';
import {
  getDockets,
  saveBumpedDocket,
  saveRecalledDocket,
  saveDocketsOverwrite,
  saveDockets,
  swapUpdatedDockets,
} from '../storage/docket';
import config from '../config';
import { Docket, DocketItemStatus, DocketStatus } from '@oolio-group/domain';
import { DocketSource } from '../common/types';
import { useOrgTimings } from './useOrgTimings';
import { getStartTimeandEndTime } from '../utils/timeHelper';
import { DocketBumper } from '../screens/Main/Dockets/DocketBumper';
import { makeRequest } from '../utils/fetchHelper';
import { openDocketslastUpdatedAt } from '../state/apolloVars';
import { useOrderNotification } from './useOrderNotification';
import { useSession } from './useSession';

export interface UseDockets {
  getActiveDockets: (
    printerProfileIds: string[],
    storeId: string,
  ) => Promise<Docket[]>;
  getCompletedDockets: (
    printerProfileIds: string[],
    storeId: string,
  ) => Promise<Docket[]>;
  getDockets: (type: DocketStatus) => Promise<Docket[]>;
  bumpDocketWithStatus: (
    id: string,
    status: DocketItemStatus,
  ) => Promise<Docket>;
  recallDocket: (id: string) => Promise<Docket>;
  bumpDocketItem: (id: string) => Promise<Docket>;
  bumpDocketItems: (ids: string[]) => Promise<void>;
  serveDocketItem: (id: string) => Promise<Docket>;
  reBumpDocketItem: (id: string) => Promise<Docket>;
  recallDocketItem: (id: string) => Promise<Docket>;
}

export function useDockets(): UseDockets {
  const { dateTime } = useOrgTimings();
  const context = useContext(DocketBumper);
  const { sendBumpSmsNotification, sendReceiveSmsNotification } =
    useOrderNotification();
  const { session } = useSession();
  const { notifyOnBump, notifyOnReceive } =
    session.kitchenDisplay?.notificationConfig || {};

  const getDocketsByPrinterProfileAndStatus = useCallback(
    //TODO add store id in route or in headers
    async (ids: string[], status: DocketStatus, storeId: string) => {
      const timings = getStartTimeandEndTime(dateTime?.startTime || '');
      const input = {
        ids,
        status: status,
        ...(status == DocketStatus.COMPLETED && {
          startTime: timings.startTime,
          endTime: timings.endTime,
        }),
        ...(storeId && { storeId }),
      };
      const data = await makeRequest(
        config.KDS_SERVICE_URI + '/dockets',
        input,
      );
      const dockets = data.dockets;
      if (dockets?.length) {
        saveDocketsOverwrite(dockets, status, DocketSource.API);
        return dockets as Docket[];
      }
      return [] as Docket[];
    },
    [dateTime?.startTime],
  );
  const getActiveDockets = useCallback(
    async (printerProfileIds: string[], storeId: string) => {
      const dockets = await getDocketsByPrinterProfileAndStatus(
        printerProfileIds,
        DocketStatus.CREATED,
        storeId,
      );
      if (notifyOnReceive) {
        const unNotifiedDockets = dockets.filter(
          d => !d?.notificationStatus?.smsOnReceive,
        );
        if (unNotifiedDockets?.length) {
          sendReceiveSmsNotification(unNotifiedDockets.map(item => item.id));
        }
      }

      return dockets;
    },
    [
      getDocketsByPrinterProfileAndStatus,
      notifyOnReceive,
      sendReceiveSmsNotification,
    ],
  );

  const getCompletedDockets = useCallback(
    async (printerProfileIds: string[], storeId: string) => {
      return await getDocketsByPrinterProfileAndStatus(
        printerProfileIds,
        DocketStatus.COMPLETED,
        storeId,
      );
    },
    [getDocketsByPrinterProfileAndStatus],
  );

  const bumpDocketWithStatus = useCallback(
    async (docketId: string, status: DocketItemStatus) => {
      const input = {
        ids: [docketId],
        status: status,
      } as { ids: string[] };
      const dockets = await makeRequest(
        config.KDS_SERVICE_URI + '/docket/bump',
        input,
      );
      if (
        notifyOnBump &&
        dockets?.length &&
        !dockets[0]?.notificationStatus?.smsOnBump
      ) {
        sendBumpSmsNotification([docketId]);
      }
      if (dockets?.length) {
        saveBumpedDocket(dockets[0]);
        if (context.current)
          context.current = context.current.filter(c => c != dockets[0].id);
        openDocketslastUpdatedAt(performance.now());
        return dockets[0] as Docket;
      }
      return {} as Docket;
    },
    [context, notifyOnBump, sendBumpSmsNotification],
  );

  const bumpDocketItem = useCallback(async (docketItemId: string) => {
    const input = {
      id: docketItemId,
    } as { id: string };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-item/bump',
      input,
    );
    if (dockets?.length) {
      saveDockets(docketItemId, dockets, DocketSource.API);
      openDocketslastUpdatedAt(performance.now());
      return dockets;
    }
    return {} as Docket;
  }, []);

  const bumpDocketItems = useCallback(async (docketItemIds: string[]) => {
    const input = {
      ids: docketItemIds,
    } as { ids: string[] };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-items/bump',
      input,
    );
    if (dockets?.length) {
      await swapUpdatedDockets(dockets, DocketSource.API);

      openDocketslastUpdatedAt(performance.now());
    }
  }, []);

  const serveDocketItem = useCallback(async (docketItemId: string) => {
    const input = {
      id: docketItemId,
    } as { id: string };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-item/serve',
      input,
    );
    if (dockets?.length) {
      saveDockets(docketItemId, dockets, DocketSource.API);
      openDocketslastUpdatedAt(performance.now());
      return dockets;
    }
    return {} as Docket;
  }, []);

  const reBumpDocketItem = useCallback(async (docketItemId: string) => {
    const input = {
      id: docketItemId,
    } as { id: string };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-item/rebump',
      input,
    );
    if (dockets?.length) {
      saveDockets(docketItemId, dockets, DocketSource.API);
      openDocketslastUpdatedAt(performance.now());
      return dockets;
    }
    return {} as Docket;
  }, []);

  const recallDocketItem = useCallback(async (docketItemId: string) => {
    const input = {
      id: docketItemId,
    } as { id: string };
    const dockets = await makeRequest(
      config.KDS_SERVICE_URI + '/docket-item/recall',
      input,
    );
    if (dockets?.length) {
      saveRecalledDocket(dockets[0]);
      openDocketslastUpdatedAt(performance.now());
      return dockets;
    }
    return {} as Docket;
  }, []);

  const recallDocket = useCallback(async (docketId: string) => {
    const input = {
      id: docketId,
    } as { id: string };
    const docket = await makeRequest(
      config.KDS_SERVICE_URI + '/docket/recall',
      input,
    );
    if (docket?.id) {
      saveRecalledDocket(docket);
      openDocketslastUpdatedAt(performance.now());

      return docket as Docket;
    }
    return {} as Docket;
  }, []);

  return {
    getActiveDockets,
    getCompletedDockets,
    getDockets,
    bumpDocketWithStatus,
    recallDocket,
    bumpDocketItem,
    bumpDocketItems,
    recallDocketItem,
    serveDocketItem,
    reBumpDocketItem,
  };
}
