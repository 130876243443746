import React, { useCallback, useState } from 'react';
import {
  View,
  Text,
  Modal,
  KeyboardAvoidingView,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import { useTranslation } from '@oolio-group/localization';
import OrderMessageModalStyles from './OrderMessageModal.styles';
import { useColors } from '../../../hooks/useColors';
import { analyticsService } from '../../../analytics/AnalyticsService';
import {
  DefaultBumpMessage,
  DefaultReceiveMessage,
} from '../../../common/constants';

export enum MessageType {
  BUMP = 'Order Bump',
  RECEIVE = 'Order Receive',
}

export interface OrderMessageModalProps {
  visible: boolean;
  messageType: MessageType;
  onClose?: () => void | Promise<void>;
  onSave: (type: MessageType, text: string) => void;
  initialMessage?: string;
}

const OrderMessageModal: React.FC<OrderMessageModalProps> = ({
  visible,
  messageType,
  onClose = () => undefined,
  onSave,
  initialMessage = '',
}) => {
  const { translate } = useTranslation();
  const styles = OrderMessageModalStyles();
  const { colors } = useColors();
  const [message, setMessage] = useState<string>(initialMessage);

  const disableSaveBtn = !message.trim().length;

  const onPressSave = useCallback(() => {
    onSave(messageType, message);
  }, [onSave, messageType, message]);

  const setDefaultMessage = () => {
    switch (messageType) {
      case MessageType.BUMP:
        setMessage(DefaultBumpMessage);
        break;
      case MessageType.RECEIVE:
        setMessage(DefaultReceiveMessage);
        break;
      default:
        break;
    }

    analyticsService.capture('defaults_sms_message', {
      type: messageType,
    });
  };

  return (
    <Modal
      animationType="fade"
      onDismiss={onClose}
      onRequestClose={onClose}
      presentationStyle="overFullScreen"
      transparent={true}
      visible={visible}
    >
      <KeyboardAvoidingView style={styles.background}>
        <View style={styles.container}>
          <Text style={styles.title}>
            {translate(
              messageType === MessageType.BUMP
                ? 'kitchenDisplay.editBumpMessage'
                : 'kitchenDisplay.editReceiveMessage',
            )}
          </Text>
          <View style={styles.section}>
            <Text style={styles.subTitle}>
              {translate('kitchenDisplay.message')}
            </Text>
            <TextInput
              onChangeText={setMessage}
              value={message}
              style={styles.input}
              multiline
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.subTitle}>
              {translate('kitchenDisplay.variables')} {'\n'}
              {'{{customer}}, {{orderType}}, {{store}} and {{receiptLink}}'}
            </Text>
          </View>
          <View style={styles.section}>
            <View style={styles.action}>
              <TouchableOpacity style={styles.btn} onPress={onClose}>
                <Text style={styles.btnText}>
                  {translate('button.dismiss')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.btn, { backgroundColor: colors.negativeLight }]}
                onPress={setDefaultMessage}
              >
                <Text style={[styles.btnText, { color: colors.red }]}>
                  {translate('button.setDefault')}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.btn,
                  !disableSaveBtn && { backgroundColor: colors.green },
                ]}
                onPress={onPressSave}
                disabled={disableSaveBtn}
              >
                <Text style={styles.btnText}>
                  {translate('button.confirm')}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </KeyboardAvoidingView>
    </Modal>
  );
};

export default OrderMessageModal;
