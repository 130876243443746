import { getItem, setItem } from '../storage/interface';
import { docketsUpdated } from '../state/apolloVars';
import { Docket, DocketStatus } from '@oolio-group/domain';
import { DOCKETS } from '../common/constants';
import { DocketSource, DocketUpdate } from '../common/types';
import { detectIncomingDocketThroughPolling } from '../utils/detectIncomingDocket';
import keyBy from 'lodash/keyBy';
import { groupBy } from 'lodash';

export const getDockets = async (status: DocketStatus) => {
  return ((await getItem(DOCKETS + `#${status}`)) as Docket[]) || [];
};

export const clearExistingDockets = async (status: DocketStatus) => {
  await setItem(DOCKETS + `#${status}`, []);
  docketsUpdated({ timestamp: Date.now() });
};

export const saveDockets = async (
  docketItemId: string,
  updatedDockets: Docket[],
  source: DocketSource,
) => {
  if (updatedDockets?.length) {
    const docketsBystatus = groupBy(updatedDockets, 'status');
    Object.values(docketsBystatus).forEach(async dockets => {
      const status = dockets[0].status;
      const dbDockets =
        ((await getItem(DOCKETS + `#${status}`)) as Docket[]) || [];
      const dbDocketsMap = keyBy(dbDockets, 'id');
      dockets.forEach(docket => {
        if (dbDocketsMap[docket.id]?.id) {
          const changedItem = (docket?.docketItems || []).find(
            x => x.orderItemId == docketItemId,
          );
          if (docketItemId) {
            const updatedDocket = { ...dbDocketsMap[docket.id] };
            updatedDocket.docketItems = (updatedDocket.docketItems || []).map(
              x => {
                if (x.id == changedItem?.id) {
                  return changedItem;
                }
                return x;
              },
            );
            updatedDocket.status = docket.status;
            updatedDocket.itemStatus = docket.itemStatus;
            dbDocketsMap[docket.id] = updatedDocket;
          }
        }
      });
      await setItem(DOCKETS + `#${status}`, Object.values(dbDocketsMap));
    });
    docketsUpdated({ timestamp: Date.now(), source });
  }
};
export const swapUpdatedDockets = async (
  updatedDockets: Docket[],
  source: DocketSource,
) => {
  if (updatedDockets?.length) {
    const docketsBystatus = groupBy(updatedDockets, 'status');
    Object.values(docketsBystatus).forEach(async dockets => {
      const status = dockets[0].status;
      const dbDockets =
        ((await getItem(DOCKETS + `#${status}`)) as Docket[]) || [];
      const dbDocketsMap = keyBy(dbDockets, 'id');
      dockets.forEach(docket => {
        if (dbDocketsMap[docket.id]?.id) {
          dbDocketsMap[docket.id] = docket;
        }
      });
      await setItem(DOCKETS + `#${status}`, Object.values(dbDocketsMap));
    });
    docketsUpdated({ timestamp: Date.now(), source });
  }
};

export const saveDocketsOverwrite = async (
  dockets: Docket[],
  status: DocketStatus,
  source?: DocketSource,
) => {
  const docketUpdateVal: DocketUpdate = {
    timestamp: Date.now(),
    source: source,
  };

  if (dockets.length == 0) {
    await clearExistingDockets(status);
    return;
  }
  if (source == DocketSource.POOLING && status == DocketStatus.CREATED) {
    const existingDockets =
      ((await getItem(DOCKETS + `#${status}`)) as Docket[]) || [];
    if (detectIncomingDocketThroughPolling(dockets, existingDockets)) {
      docketUpdateVal['triggerSound'] = true;
    }
  }
  await setItem(DOCKETS + `#${status}`, dockets);
  docketsUpdated(docketUpdateVal);
  return dockets;
};

export const saveBumpedDocket = async (docketToBump: Docket) => {
  const docketId = docketToBump?.id;
  if (!docketId) return;
  let docketsToSaveInCurrent: Docket[] = [];
  let docketsToSaveInCompleted: Docket[] = [];
  docketsToSaveInCurrent =
    ((await getItem(DOCKETS + `#${DocketStatus.CREATED}`)) as Docket[]) || [];
  docketsToSaveInCompleted =
    ((await getItem(DOCKETS + `#${DocketStatus.COMPLETED}`)) as Docket[]) || [];
  const docket = docketsToSaveInCurrent.find(
    (dckt: Docket) => dckt.id == docketId,
  );
  docketsToSaveInCurrent = docketsToSaveInCurrent.filter(
    (dckt: Docket) => dckt.id !== docketId,
  );
  if (docket) {
    docketsToSaveInCompleted.push(docketToBump);
    await setItem(DOCKETS + `#${DocketStatus.CREATED}`, docketsToSaveInCurrent);
    await setItem(
      DOCKETS + `#${DocketStatus.COMPLETED}`,
      docketsToSaveInCompleted,
    );
    docketsUpdated({ timestamp: Date.now() });
  }
  return docketsToSaveInCurrent;
};

export const saveRecalledDocket = async (docketToRecall: Docket) => {
  const docketId = docketToRecall?.id;
  if (!docketId) return;
  let docketsToSaveInCurrent: Docket[] = [];
  let docketsToSaveInCompleted: Docket[] = [];
  docketsToSaveInCurrent =
    ((await getItem(DOCKETS + `#${DocketStatus.CREATED}`)) as Docket[]) || [];
  docketsToSaveInCompleted =
    ((await getItem(DOCKETS + `#${DocketStatus.COMPLETED}`)) as Docket[]) || [];
  const docket = docketsToSaveInCompleted.find(
    (dckt: Docket) => dckt.id == docketId,
  );
  docketsToSaveInCompleted = docketsToSaveInCompleted.filter(
    (dckt: Docket) => dckt.id !== docketId,
  );
  if (docket) {
    docketsToSaveInCurrent.push(docketToRecall);
    await setItem(DOCKETS + `#${DocketStatus.CREATED}`, docketsToSaveInCurrent);
    await setItem(
      DOCKETS + `#${DocketStatus.COMPLETED}`,
      docketsToSaveInCompleted,
    );
    docketsUpdated({ timestamp: Date.now() });
  }
  return docketsToSaveInCurrent;
};
