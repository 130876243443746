import React from 'react';
import { View, Modal, StyleSheet, ActivityIndicator } from 'react-native';
import theme from '../../common/theme';

const Loader = ({ show }: { show: boolean }) => (
  <Modal visible={show} transparent={true}>
    <View style={styles.container}>
      <ActivityIndicator size="large" color={theme.colors.white} />
    </View>
  </Modal>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.modalBg,
  },
});

export default Loader;
