import React, { useLayoutEffect, useState } from 'react';
import { View, useWindowDimensions } from 'react-native';
import { DocketItemStatus, DocketStatus } from '@oolio-group/domain';
import { useScale } from '../../../hooks/useScale';
import { useSession } from '../../../hooks/useSession';
import { useDockets } from '../../../hooks/useDockets';
import { useOrderTypes } from '../../../hooks/useOrderTypes';
import { useSalesChannels } from '../../../hooks/useSalesChannels';
import DocketItem from '../../../component/DocketItem/DocketItem';
import { PaginatedDocketItems } from '../../../utils/layoutHelper';
import MainScreenStyles from '../MainScreen.styles';
import { DOCKET_WIDTH } from '../../../common/constants';

export interface DocketsGridProps {
  displayDocketItems: PaginatedDocketItems[];
  status: DocketStatus;
  currentPage: number;
}

const DocketsGrid: React.FC<DocketsGridProps> = ({
  displayDocketItems,
  status,
  currentPage,
}) => {
  const {
    bumpDocketWithStatus,
    recallDocket,
    bumpDocketItem,
    recallDocketItem,
    serveDocketItem,
    reBumpDocketItem,
  } = useDockets();
  const { scale } = useScale();
  const { session } = useSession();
  const styles = MainScreenStyles();
  const { orderTypes } = useOrderTypes();
  const { width } = useWindowDimensions();
  const { salesChannels } = useSalesChannels();

  const [itemWidth, setItemWidth] = useState<number>(0);

  useLayoutEffect(() => {
    const columnsPossible = Math.floor(width / scale(DOCKET_WIDTH));
    setItemWidth(Math.floor(width / columnsPossible - scale(12)));
  }, [scale, width]);

  const handleOnPressDocketHeader = async (docketId: string) => {
    if (status == DocketStatus.CREATED)
      return bumpDocketWithStatus(
        docketId,
        session?.rebumpToggle
          ? DocketItemStatus.REBUMPED
          : DocketItemStatus.BUMPED,
      );
    else return recallDocket(docketId);
  };

  const handleOnPressDocketItem = async (
    docketItemId: string,
    itemStatus: DocketItemStatus,
  ) => {
    if (
      itemStatus == DocketItemStatus.CREATED ||
      itemStatus == DocketItemStatus.RECALLED
    ) {
      await bumpDocketItem(docketItemId);
    } else if (
      status == DocketStatus.CREATED &&
      itemStatus == DocketItemStatus.BUMPED
    ) {
      session?.rebumpToggle
        ? await reBumpDocketItem(docketItemId)
        : await serveDocketItem(docketItemId);
    } else if (
      status == DocketStatus.CREATED &&
      itemStatus == DocketItemStatus.REBUMPED
    ) {
      await serveDocketItem(docketItemId);
    } else {
      await recallDocketItem(docketItemId);
    }
  };

  return (
    <View style={styles.grid}>
      {displayDocketItems[currentPage]?.columns.map(col =>
        col.items.map((item, i) => (
          <View key={i} style={[styles.item, { width: itemWidth }]}>
            <DocketItem
              key={item.name + i}
              item={item}
              status={status}
              orderTypes={orderTypes}
              salesChannels={salesChannels}
              onPressDocketHeader={handleOnPressDocketHeader}
              onPressDocketItem={handleOnPressDocketItem}
            />
          </View>
        )),
      )}
    </View>
  );
};

export default DocketsGrid;
