import theme from '../common/theme';
import { INTERFACE_HEIGHT } from '../common/constants';
import { DocketDisplayItem } from '../common/types';

export interface PaginatedDocketItems {
  page: number;
  columns: {
    column: number;
    items: DocketDisplayItem[];
  }[];
}

export const getPaginatedDocketItems = (
  items: DocketDisplayItem[],
  colWidth: number,
  scale: (n: number) => number,
): PaginatedDocketItems[] => {
  const { vw, vh } = theme.window;
  const colHeight = vh - INTERFACE_HEIGHT;
  const colsPerPage = Math.floor(vw / colWidth);

  let currentPage = 1;
  let currentColumn = 1;
  let currentHeight = 0;
  let currentColumnObject: { column: number; items: DocketDisplayItem[] } = {
    column: currentColumn,
    items: [],
  };

  const pages: PaginatedDocketItems[] = [
    { page: currentPage, columns: [currentColumnObject] },
  ];

  items.forEach(item => {
    // if current column is full, create new column
    if (currentHeight + scale(item.height) > colHeight) {
      currentColumn++;
      currentColumnObject = { column: currentColumn, items: [] };
      pages[currentPage - 1].columns.push(currentColumnObject);
      currentHeight = 0;
    }

    // if current page is full, create new page
    if (currentColumn - 1 >= colsPerPage) {
      currentPage++;
      currentColumn = 1;
      currentColumnObject = { column: currentColumn, items: [] };
      pages.push({ page: currentPage, columns: [currentColumnObject] });
      currentHeight = 0;
    }

    // add item to column
    currentColumnObject.items.push(item);
    currentHeight += scale(item.height);
  });

  return pages;
};
