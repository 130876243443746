import * as settings from '../state/preferences';

export type headers = {
  'Content-Type': string;
  authorization?: string;
};

export function getTokenFromHeader(headers: headers): string {
  return headers?.authorization?.replace('Bearer ', '') || '';
}

export async function getHeaders() {
  const session = await settings.getSession();
  const headers: headers = {
    'Content-Type': 'application/json',
  };
  if (session?.token) {
    headers['authorization'] = `Bearer ${session?.token}`;
  }
  return headers;
}
