import {
  REACT_APP_API_URL,
  REACT_APP_OAUTH2_AUDIENCE,
  REACT_APP_OAUTH2_CLIENTID,
  REACT_APP_OOLIO_DOMAIN,
  REACT_APP_OOLIO_SERVICE_URI,
  REACT_APP_SUBSCRIPTION_URI,
} from 'react-native-dotenv';

const config = {
  SERVICE_URI: process.env['REACT_APP_API_URL'] || REACT_APP_API_URL,
  USERINFO_URL:
    'https://' +
    (process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN) +
    '/userinfo',
  KDS_SERVICE_URI:
    (process.env['REACT_APP_OOLIO_SERVICE_URI'] ||
      REACT_APP_OOLIO_SERVICE_URI) + '/pos/kds',
  SUBSCRIPTION__URL:
    process.env['REACT_APP_SUBSCRIPTION_URI'] || REACT_APP_SUBSCRIPTION_URI,
  DEFAULT_COUNTRY_CODE: 'AU',
  DEFAULT_LOCALE: 'en-US',
  REACT_APP_OOLIO_SERVICE_URI:
    process.env['REACT_APP_OOLIO_SERVICE_URI'] || 'test-in',
  auth0: {
    issuer:
      'https://auth.' +
      (process.env['REACT_APP_OOLIO_DOMAIN'] || REACT_APP_OOLIO_DOMAIN),
    clientId:
      process.env['REACT_APP_OAUTH2_CLIENTID'] || REACT_APP_OAUTH2_CLIENTID,
    redirectUrl: '',
    additionalParameters: {
      audience:
        process.env['REACT_APP_OAUTH2_AUDIENCE'] || REACT_APP_OAUTH2_AUDIENCE,
    },
    scopes: ['openid', 'profile', 'email', 'offline_access'],
  },
};

export default config;
