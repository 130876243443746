import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import {
  KitchenDisplay,
  Organization,
  Store,
  Venue,
} from '@oolio-group/domain';
import { useNavigation } from '@react-navigation/native';
import { useVenues } from '../../hooks/organization/useVenues';
import { useKitchenDisplay } from '../../hooks/useKitchenDisplay';
import { useSession } from '../../hooks/useSession';
import Gradient from '../../component/Gradient/Gradient';
import { useDeviceId } from '../../hooks/useDeviceId';
import styles from './DeviceSelection.styles';
import DeviceList from './DevicesList';
import LocationsList from './LocationsList';
import ButtonIcon from '../../component/Buttons/ButtonIcon';
import {
  POSTHOG_EVENT_ACTIONS,
  POSTHOG_EVENT_DATA,
  analyticsService,
} from '../../analytics/AnalyticsService';
import { Session } from '../../state/preferences';
import OrganizationsList from './OrganizationsList';
import { useSsoProfile } from '@oolio-group/hooks';
import config from '../../config';

export enum Steps {
  ORGANIZATION = 'Organization',
  STORE = 'Store',
  DEVICE = 'Device',
  PRINTER_PROFILE = 'Printer Profile',
}

const DeviceSelection: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const navigation = useNavigation<any>();
  const [step, setStep] = useState<Steps>(Steps.ORGANIZATION);
  const { setSession, session } = useSession();
  const { user, loading: userLoading } = useSsoProfile(
    config.USERINFO_URL,
    session.token as string,
  );
  const { venues, loading: venueLoading, refetch: refetchVenue } = useVenues();
  const { deviceId } = useDeviceId();

  const {
    kitchenDisplays,
    loading: kitchenDisplayLoading,
    getKitchenDisplays,
    updateKitchenDisplay,
  } = useKitchenDisplay({ storeId: session?.currentStore?.id });

  const setUserToPostHog = (session: Partial<Session>) => {
    // Identify User
    analyticsService.identify(session?.currentOrganization?.id as string, {
      id: session?.currentOrganization?.id,
      name: session?.currentOrganization?.name,
      venue: session?.currentVenue?.name,
      store: session?.currentStore?.name,
    });

    // Identify Organisation
    analyticsService.group(
      'organization',
      session?.currentOrganization?.id as string,
      {
        id: session?.currentOrganization?.id,
        name: session?.currentOrganization?.name,
        venue: session?.currentVenue?.name,
        store: session?.currentStore?.name,
      },
    );
  };

  const onSelectOrganization = useCallback(
    async (organization: Pick<Organization, 'id' | 'name'>) => {
      // if (session.currentOrganization) return;
      await setSession({
        ...session,
        currentOrganization: organization,
      });
      refetchVenue();
      setStep(Steps.STORE);
    },
    [refetchVenue, session, setSession],
  );

  const onSelectStore = useCallback(
    async (venue: Partial<Venue>, store: Store) => {
      setSession({
        authorized: true,
        currentVenue: venue,
        currentStore: store,
      });
      setStep(Steps.DEVICE);
      getKitchenDisplays();
      setUserToPostHog(session);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getKitchenDisplays, session],
  );

  const kitchenDisplayList = useMemo(
    () => Object.values(kitchenDisplays),
    [kitchenDisplays],
  );

  const onSelectKitchenDisplay = useCallback(
    async (kitchenDisplay: KitchenDisplay) => {
      analyticsService.capture(POSTHOG_EVENT_ACTIONS.DEVICE_SELECTED, {
        kdsName: kitchenDisplay.name,
        device: kitchenDisplay?.id,
        printerProfiles: (kitchenDisplay?.printerProfiles || [])?.map(x => ({
          name: x.name,
          id: x.id,
        })),
        orgId: session?.currentOrganization?.id,
        orgName: session?.currentOrganization?.name,
      } as POSTHOG_EVENT_DATA);

      updateKitchenDisplay({
        id: kitchenDisplay.id,
        isPaired: true,
        uuid: deviceId,
      });
      kitchenDisplay.uuid = deviceId;

      setSession({ kitchenDisplay });

      navigation.navigate('MainScreen');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateKitchenDisplay, deviceId, navigation, session],
  );

  const onBack = useCallback(() => {
    if (step === Steps.STORE) {
      navigation.navigate('LoginScreen');
    } else {
      setStep(Steps.STORE);
    }
  }, [step, navigation]);

  useEffect(() => {
    switch (step) {
      case Steps.ORGANIZATION:
        if (user?.organizations?.length === 1) {
          onSelectOrganization(user.organizations[0]);
        } else if (session.currentOrganization?.id) {
          setStep(Steps.STORE);
        }
        break;
      case Steps.STORE:
        if (venues?.length === 1 && venues?.[0]?.stores?.length === 1)
          onSelectStore(venues[0], venues[0].stores[0]);
        break;
      case Steps.DEVICE:
        break;
    }
  }, [
    step,
    venues,
    onSelectStore,
    onSelectOrganization,
    user?.organizations,
    session.currentOrganization?.id,
  ]);

  return (
    <Gradient>
      <View style={styles.screen}>
        <View style={styles.modalContainer}>
          <ButtonIcon
            testID="btn-back"
            icon="arrow-left"
            type="cancel"
            onPress={onBack}
            containerStyle={styles.btnBack}
          />
          {step === Steps.ORGANIZATION && (
            <OrganizationsList
              organizations={user?.organizations ?? []}
              onSelect={onSelectOrganization}
              loading={userLoading}
            />
          )}
          {step === Steps.STORE && (
            <LocationsList
              venuesData={venues ?? []}
              onSelect={onSelectStore}
              loading={venueLoading}
            />
          )}
          {step === Steps.DEVICE && (
            <DeviceList
              onSelect={onSelectKitchenDisplay}
              loading={kitchenDisplayLoading}
              devices={kitchenDisplayList ?? []}
            />
          )}
        </View>
      </View>
    </Gradient>
  );
};

export default DeviceSelection;
