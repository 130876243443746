import React, { useCallback, useState } from 'react';
import { useSession } from '../../hooks/useSession';
import { useSettings } from '../../hooks/useSettings';
import { usePrinterProfiles } from '../../hooks/usePrinterProfiles';
import SelectPrinterProfileModal from '../../component/Modals/PrinterProfile/PrinterProfileModal';

const AssignPrinterProfile: React.FC = () => {
  const { session } = useSession();
  const { updatePrinterProfiles, loading } = useSettings();
  const { printerProfiles } = usePrinterProfiles();

  const [visible, setVisible] = useState(true);

  const hasPrinterProfilesAssigned =
    session?.kitchenDisplay?.printerProfiles?.length;

  const onCloseModal = useCallback(() => {
    setVisible(false);
  }, []);

  const onAssignProfiles = useCallback(
    (profileIDs: string[]) => {
      if (profileIDs.length) updatePrinterProfiles(profileIDs, onCloseModal);
    },
    [onCloseModal, updatePrinterProfiles],
  );

  return (
    <>
      {!hasPrinterProfilesAssigned ? (
        <SelectPrinterProfileModal
          visible={visible}
          loading={loading}
          printerProfiles={printerProfiles}
          onClose={onCloseModal}
          onSave={onAssignProfiles}
        />
      ) : null}
    </>
  );
};

export default AssignPrinterProfile;
