import React, { FC, useCallback, useMemo, useState } from 'react';
import { View, Text, ScrollView, ActivityIndicator } from 'react-native';
import { PrinterProfile } from '@oolio-group/domain';
import {
  useTranslation,
  supportedLocales,
  Locale,
} from '@oolio-group/localization';
import {
  POSTHOG_EVENT_ACTIONS,
  POSTHOG_EVENT_DATA,
  analyticsService,
} from '../../analytics/AnalyticsService';
import { useSession } from '../../hooks/useSession';
import { useNavigation } from '@react-navigation/native';
import { Nav } from '../../common/types';
import { useScale } from '../../hooks/useScale';
import { useColors } from '../../hooks/useColors';
import { useRestart } from '../../hooks/useRestart';
import { useSettings } from '../../hooks/useSettings';
import { useFetchSettings } from '../../hooks/useFetchSettings';
import { usePrinterProfiles } from '../../hooks/usePrinterProfiles';
import {
  TEXT_SIZE_OPTIONS,
  DefaultBumpMessage,
  DefaultReceiveMessage,
} from '../../common/constants';
import { Theme as ThemeOptions } from '../../common/types';
import SettingsScreenStyles from './Settings.styles';
import Logo from '../../component/Logo/Logo';
import ButtonIcon from '../../component/Buttons/ButtonIcon';
import SettingsItem from '../../component/SettingsItem/SettingsItem';
import OrderMessageModal, {
  MessageType,
} from '../../component/Modals/OrderMessage/OrderMessageModal';
import SettingsAction from '../../component/SettingsItem/SettingsAction';
import { useKitchenDisplay } from '../../hooks/useKitchenDisplay';
import { startCase } from 'lodash';

const SettingsScreen: FC = () => {
  const styles = SettingsScreenStyles();
  const { translate } = useTranslation();
  const { restartDevice } = useRestart();
  const navigation = useNavigation<Nav>();
  const { session, setSession } = useSession();
  const { audioToggle, logout } = useSettings();
  const { setScale, currentScale, scale } = useScale();
  const { colors, activeTheme, changeTheme } = useColors();
  const { loading, fetchSettings } = useFetchSettings();
  const { printerProfiles, assignPrinterProfiles } = usePrinterProfiles();
  const [modalVisible, setModalVisible] = useState(false);
  const [messageType, setMessageType] = useState<MessageType>(MessageType.BUMP);

  const [locale, setLocale] = useState(session?.localeCode);
  const [showLanguages, setShowLanguages] = useState<boolean>(false);
  const [showTextSizes, setShowTextSizes] = useState<boolean>(false);
  const [isTwoLevel, setIsTwoLevel] = useState<boolean>(
    session?.rebumpToggle || false,
  );

  const { updateKitchenDisplay } = useKitchenDisplay({
    storeId: session?.currentStore?.id,
  });

  const {
    notifyOnBump,
    notifyOnReceive,
    bumpMessage: savedBumpMessage,
    receiveMessage: savedReceiveMessage,
  } = session?.kitchenDisplay?.notificationConfig || {};

  const notificationConfig = {
    notifyOnBump: notifyOnBump || false,
    notifyOnReceive: notifyOnReceive || false,
    bumpMessage: savedBumpMessage || DefaultBumpMessage,
    receiveMessage: savedReceiveMessage || DefaultReceiveMessage,
  };
  const isDarkMode = activeTheme === ThemeOptions.DARK;

  const assignedProfiles = useMemo(
    () =>
      session?.kitchenDisplay?.printerProfiles?.map(
        (x: PrinterProfile) => x.id,
      ) || [],
    [session?.kitchenDisplay?.printerProfiles],
  );

  const availableLocales = useMemo(
    () =>
      Object.values(supportedLocales).filter(
        locale =>
          !(
            locale.languageTag.includes('en-') && locale.languageTag !== 'en-US'
          ),
      ),
    [],
  );

  const currentLocale = availableLocales.find(
    language => language.languageTag == locale,
  );

  const onToggleProfile = (printerProfile: PrinterProfile) => {
    analyticsService.capture('Changed Printer Profile');
    const profileId = printerProfile?.id;
    if (!profileId) {
      return;
    }

    const index = assignedProfiles.indexOf(profileId);
    const profilesToAssign = [...assignedProfiles];

    if (index !== -1) {
      if (assignedProfiles.length > 1) {
        profilesToAssign.splice(index, 1);
      } else {
        return;
      }
    } else {
      profilesToAssign.push(profileId);
    }

    if (
      profilesToAssign.length > 0 &&
      JSON.stringify(profilesToAssign) !== JSON.stringify(assignedProfiles)
    ) {
      assignPrinterProfiles(profilesToAssign);
    }
  };

  const onToggleTwoLevel = () => {
    analyticsService.capture('Toggled 2-Level Fulfilment');
    setIsTwoLevel(!isTwoLevel);
    setSession({ rebumpToggle: !isTwoLevel });
  };

  const onToggleDarkMode = () => {
    if (isDarkMode) {
      analyticsService.capture('Changed Theme to Light');
      changeTheme(ThemeOptions.LIGHT);
    } else {
      analyticsService.capture('Changed Theme to Dark');
      changeTheme(ThemeOptions.DARK);
    }
  };

  const onChangeLanguage = (language: Locale) => {
    analyticsService.capture('Changed Language');
    setLocale(language.languageTag);
    setSession({ localeCode: language.languageTag });
    restartDevice();
  };

  const onPressBack = () => {
    if (session.authorized) {
      navigation.navigate('MainScreen');
    }
  };

  const onPressSync = () => {
    analyticsService.capture('Sync Requested');
    fetchSettings(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };
  const openModal = (type: MessageType) => {
    setMessageType(type);
    setModalVisible(true);
  };
  const onSaveMessage = (pref: MessageType, message: string) => {
    if (pref === MessageType.BUMP) {
      updateKitchenDisplay({
        id: session?.kitchenDisplay?.id || '',
        notificationConfig: {
          ...notificationConfig,
          bumpMessage: message,
        },
      });
      setSession({
        kitchenDisplay: {
          ...session.kitchenDisplay,
          notificationConfig: {
            ...notificationConfig,
            bumpMessage: message,
          },
        },
      });
    } else {
      updateKitchenDisplay({
        id: session?.kitchenDisplay?.id || '',
        notificationConfig: {
          ...notificationConfig,
          receiveMessage: message,
        },
      });
      setSession({
        kitchenDisplay: {
          ...session.kitchenDisplay,
          notificationConfig: {
            ...notificationConfig,
            receiveMessage: message,
          },
        },
      });
    }
    analyticsService.capture('sms_message', {
      type: pref,
      message: message,
    });
    setModalVisible(false);
  };

  const onChangePreference = (pref: MessageType, value: boolean) => {
    switch (pref) {
      case MessageType.BUMP:
        updateKitchenDisplay({
          id: session?.kitchenDisplay?.id || '',
          notificationConfig: {
            ...notificationConfig,
            notifyOnBump: value,
          },
        });
        setSession({
          kitchenDisplay: {
            ...session.kitchenDisplay,
            notificationConfig: {
              ...notificationConfig,
              notifyOnBump: value,
            },
          },
        });
        break;
      case MessageType.RECEIVE:
        updateKitchenDisplay({
          id: session?.kitchenDisplay?.id || '',
          notificationConfig: {
            ...notificationConfig,
            notifyOnReceive: value,
          },
        });
        setSession({
          kitchenDisplay: {
            ...session.kitchenDisplay,
            notificationConfig: {
              ...notificationConfig,
              notifyOnReceive: value,
            },
          },
        });
        break;

      default:
        break;
    }
    analyticsService.capture('enabled_sms_message', {
      type: pref,
      enabled: value,
    });
  };

  const onPressLogout = useCallback(() => {
    analyticsService.capture(POSTHOG_EVENT_ACTIONS.LOGOUT, {
      device: session.kitchenDisplay?.id,
      kdsName: session?.kitchenDisplay?.name,
      store: session?.currentStore?.id,
      orgId: session?.currentOrganization?.id,
      orgName: session?.currentOrganization?.name,
    } as POSTHOG_EVENT_DATA);
    if (session?.kitchenDisplay?.id) logout(session?.kitchenDisplay?.id);
  }, [session, logout]);

  return (
    <View style={styles.screen} ph-label="settings-screen">
      <View style={styles.header}>
        <View style={styles.buttons}>
          <ButtonIcon
            ignoreTheme
            testID="btn-back"
            icon="arrow-left"
            type="interface"
            onPress={onPressBack}
          />
        </View>
        <View style={styles.title}>
          <Text style={styles.titleText}>
            {translate('kitchenDisplay.settings')}
          </Text>
        </View>
        <View style={styles.buttons}>
          <ButtonIcon
            ignoreTheme
            icon="sync"
            testID="btn-sync"
            type="interface"
            loading={loading}
            onPress={onPressSync}
          />
        </View>
      </View>
      <ScrollView style={styles.scrollView}>
        <View style={styles.content}>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>
              {translate('kitchenDisplay.general')}
            </Text>
            <View>
              <SettingsItem
                type="text"
                testID="kds-name"
                title={translate('kitchenDisplay.displayName')}
                subtitle={session?.kitchenDisplay?.name}
              />
            </View>
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>
              {translate('kitchenDisplay.printerProfile')}
            </Text>
            {printerProfiles.length ? (
              printerProfiles?.map((profile, i) => (
                <SettingsItem
                  key={i}
                  type="select"
                  testID="select-profile"
                  loading={loading}
                  title={profile.name}
                  onChange={() => onToggleProfile(profile)}
                  checked={assignedProfiles.includes(profile.id) && !loading}
                />
              ))
            ) : (
              <View>
                <ActivityIndicator color={colors.text} />
              </View>
            )}
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>
              {translate('kitchenDisplay.behaviour')}
            </Text>
            <SettingsItem
              type="switch"
              testID="toggle-twoLevel"
              loading={loading}
              title={translate('kitchenDisplay.rebumpToggle')}
              onChange={onToggleTwoLevel}
              checked={isTwoLevel}
            />
          </View>
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>
              {translate('kitchenDisplay.appearance')}
            </Text>
            <SettingsItem
              type="parent"
              testID="select-textSize"
              title={translate('kitchenDisplay.textSize')}
              subtitle={translate(
                `kitchenDisplay.${startCase(currentScale?.toLowerCase())}`,
              )}
              checked={showTextSizes}
              onChange={() => setShowTextSizes(!showTextSizes)}
            />
            {showTextSizes ? (
              <View style={styles.subSection}>
                {TEXT_SIZE_OPTIONS.map((size, i) => (
                  <SettingsItem
                    key={i}
                    type="select"
                    testID={`textSize-${size.label.toLowerCase()}`}
                    title={translate(`kitchenDisplay.${size.label}`)}
                    checked={size.value === currentScale}
                    onChange={() => {
                      setScale(size.value);
                      analyticsService.capture(
                        `Text Scale Changed to ${size.value}`,
                      );
                    }}
                  />
                ))}
              </View>
            ) : null}
            <SettingsItem
              type="switch"
              testID="toggle-darkMode"
              title={translate('kitchenDisplay.darkMode')}
              checked={isDarkMode}
              onChange={onToggleDarkMode}
            />
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>
              {translate('kitchenDisplay.smsMessaging')}
            </Text>
            <SettingsItem
              type="switch"
              testID="order-received-switch"
              title={translate('kitchenDisplay.orderReceived')}
              checked={notifyOnReceive}
              onChange={() =>
                onChangePreference(MessageType.RECEIVE, !notifyOnReceive)
              }
            />
            <View style={[styles.subSection, { marginBottom: scale(2) }]}>
              <SettingsItem
                type="action"
                testID={'message-on-received'}
                title={translate('kitchenDisplay.message')}
                subtitle={notificationConfig.receiveMessage}
                onChange={() => openModal(MessageType.RECEIVE)}
              />
            </View>
            <SettingsItem
              type="switch"
              testID="order-bump-switch"
              title={translate('kitchenDisplay.orderBumped')}
              checked={notifyOnBump}
              onChange={() =>
                onChangePreference(MessageType.BUMP, !notifyOnBump)
              }
            />
            <View style={styles.subSection}>
              <SettingsItem
                type="action"
                testID={'message-on-bump'}
                title={translate('kitchenDisplay.message')}
                subtitle={notificationConfig.bumpMessage}
                onChange={() => openModal(MessageType.BUMP)}
              />
            </View>
          </View>

          <View style={styles.section}>
            <Text style={styles.sectionTitle}>
              {translate('kitchenDisplay.other')}
            </Text>
            <SettingsItem
              type="switch"
              testID="toggle-audio"
              title={translate('kitchenDisplay.audioToggle')}
              checked={session.audioToggle || false}
              onChange={audioToggle}
            />
            <SettingsItem
              type="parent"
              testID="select-language"
              title={translate('kitchenDisplay.Language')}
              checked={showLanguages}
              subtitle={currentLocale?.label}
              onChange={() => setShowLanguages(!showLanguages)}
            />
            {showLanguages ? (
              <View style={styles.subSection}>
                {availableLocales?.map((locale, i) => (
                  <SettingsItem
                    key={i}
                    type="select"
                    testID={`language-${locale.label.toLowerCase()}`}
                    title={locale.label}
                    onChange={() => onChangeLanguage(locale)}
                  />
                ))}
              </View>
            ) : null}
          </View>
          <View style={styles.section}>
            <SettingsAction
              type="negative"
              testID="btn-logout"
              onPress={onPressLogout}
              label={translate('kitchenDisplay.logout')}
            />
          </View>
          <View style={styles.footnote}>
            <Text style={styles.footnoteText}>Powered By</Text>
            <Logo color="mono" height={18} />
          </View>
        </View>
      </ScrollView>
      <OrderMessageModal
        key={`${modalVisible}`}
        visible={modalVisible}
        messageType={messageType}
        onClose={closeModal}
        onSave={onSaveMessage}
        initialMessage={
          messageType === MessageType.BUMP
            ? notificationConfig.bumpMessage
            : notificationConfig.receiveMessage
        }
      />
    </View>
  );
};

export default SettingsScreen;
