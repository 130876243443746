import { gql } from '@apollo/client';

export const FRAGMENTS = {
  kdsDetails: gql`
    fragment kdsDetails on KitchenDisplay {
      id
      name
      deviceCode
      isPaired
      details
      uuid
      store {
        id
      }
      lastDisconnectedAt
      printerProfiles {
        id
        name
        locale
        printerProfileType
        orderTypes
      }
      notificationConfig {
        notifyOnBump
        notifyOnReceive
        bumpMessage
        receiveMessage
      }
    }
  `,
};

export const GET_KITCHEN_DISPLAY_BY_ID_QUERY = gql`
  query kitchenDisplay($id: ID!) {
    kitchenDisplay(id: $id) {
      ...kdsDetails
    }
  }
  ${FRAGMENTS.kdsDetails}
`;

export const GET_KITCHEN_DISPLAY_BY_STORE_QUERY = gql`
  query kitchenDisplays {
    kitchenDisplays {
      ...kdsDetails
    }
  }
  ${FRAGMENTS.kdsDetails}
`;

export const CREATE_KITCHEN_DISPLAY = gql`
  mutation createKitchenDisplay($input: CreateKitchenDisplayInput!) {
    createKitchenDisplay(input: $input) {
      ...kdsDetails
    }
  }
  ${FRAGMENTS.kdsDetails}
`;

export const UPDATE_KITCHEN_DISPLAY = gql`
  mutation updateKitchenDisplay($input: UpdateKitchenDisplayInput!) {
    updateKitchenDisplay(input: $input) {
      ...kdsDetails
    }
  }
  ${FRAGMENTS.kdsDetails}
`;

export const DELETE_KITCHEN_DISPLAY = gql`
  mutation deleteKitchenDisplay($id: ID!) {
    deleteKitchenDisplay(id: $id)
  }
`;
export const RESET_KITCHEN_DISPLAY = gql`
  mutation resetKitchenDisplayCode($id: ID!) {
    resetKitchenDisplayCode(id: $id) {
      deviceCode
    }
  }
`;
